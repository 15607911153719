<template>
  <div class="noodle">
    <section><img src="../assets/noodle/image-1.png" /></section>
    <section><img src="../assets/noodle/image-2.png" /></section>
    <section><img src="../assets/noodle/image-3.png" /></section>
    <section><img src="../assets/noodle/image-4.png" /></section>
    <section><img src="../assets/noodle/image-5.png" /></section>
    <section><img src="../assets/noodle/image-6.png" /></section>
    <section><img src="../assets/noodle/image-7.png" /></section>
    <section class="section8">
      <img src="../assets/noodle/image-8.png" />
      <swiper :options="swiperOptions">
        <swiper-slide
          ><img src="../assets/noodle/section8/section8-1.png"
        /></swiper-slide>
        <swiper-slide
          ><img src="../assets/noodle/section8/section8-2.png"
        /></swiper-slide>
        <swiper-slide
          ><img src="../assets/noodle/section8/section8-3.png"
        /></swiper-slide>
      </swiper>
    </section>
    <section class="section9">
      <img src="../assets/noodle/image-9.png" />
      <div class="form">
        <input
          type="text"
          ref="name"
          placeholder="이름을 입력해주세요"
          v-model="name"
        />
        <input
          type="tel"
          ref="phone"
          placeholder="연락처를 입력해주세요"
          v-model="phone"
        />
        <input
          type="email"
          ref="email"
          placeholder="이메일 주소를 입력해주세요"
          v-model="email"
        />
        <input
          type="text"
          ref="area"
          placeholder="창업 희망 지역을 입력해주세요"
          v-model="area"
        />
        <input
          type="text"
          ref="visit_path"
          placeholder="방문 경로를 입력해주세요"
          v-model="visit_path"
        />

        <input type="checkbox" v-model="agree" />
        <div class="agree">
          <div class="text">[필수] 개인정보수집 이용 동의</div>
        </div>
      </div>
      <button class="btn" @click="counselingRequest" />
    </section>
  </div>
</template>

<script>
import { counseling } from "@/api/counseling";

export default {
  name: "NoodleView",
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      area: "",
      visit_path: "",
      agree: false,
      swiperOptions: {
        loop: true, // 무한 루프
        spaceBetween: "5%", // 슬라이드 마다 간격
        autoplay: {
          delay: 3000, // 3초마다 자동으로 슬라이드 전환
          disableOnInteraction: false, // 사용자 상호작용 시 자동 재생 유지
        },
        centeredSlides: true, // 현재 슬라이드가 가운데에 위치하도록 설정
        slidesPerView: 2.5, // 한 번에 보이는 슬라이드 개수
      },
    };
  },
  methods: {
    async counselingRequest() {
      if (this.name.length < 1) {
        alert("이름을 입력해주세요");
        this.$refs.name.focus();
        return;
      }

      if (this.phone.length < 1) {
        alert("연락처를 입력해주세요");
        this.$refs.phone.focus();
        return;
      }

      if (this.email.length < 1) {
        alert("이메일 주소를 입력해주세요");
        this.$refs.email.focus();
        return;
      }

      if (this.area.length < 1) {
        alert("창업 희망 지역을 입력해주세요");
        this.$refs.area.focus();
        return;
      }

      if (this.visit_path.length < 1) {
        alert("방문 경로를 입력해주세요");
        this.$refs.visit_path.focus();
        return;
      }

      if (!this.agree) {
        alert("개인정보수집 이용 동의에 체크 해주세요");
        return;
      }

      try {
        await counseling({
          domain: location.hostname,
          path: location.pathname,
          name: this.name,
          phone: this.phone,
          email: this.email,
          area: this.area,
          visit_path: this.visit_path,
        });
        alert("정상적으로 접수되었습니다. 감사합니다.");
        this.name = "";
        this.phone = "";
        this.email = "";
        this.area = "";
        this.visit_path = "";
        this.agree = false;
      } catch {
        alert("일시적인 오류입니다. 잠시후 다시 시도해주세요");
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.noodle {
  font-size: 0;

  .section8 {
    position: relative;

    .swiper-container {
      position: absolute;
      top: 42%;
      left: 50%;
      width: 100%;
      padding-bottom: 40px;
      transform: translateX(-50%);

      .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .section9 {
    position: relative;
    font-size: 1rem;

    .form {
      position: absolute;
      top: 14.5%;
      left: 0%;
      width: 100%;
      height: 76.2%;
      box-sizing: border-box;

      input[type="text"],
      input[type="tel"],
      input[type="email"] {
        position: absolute;
        left: 8%;
        top: 9.5%;
        height: 5%;
        width: 80%;
        border: 0;
        box-sizing: border-box;
        background-color: transparent;

        &:nth-child(2) {
          top: calc(9.5% + 14.5%);
        }

        &:nth-child(3) {
          top: calc(9.5% + 14.5% + 15%);
        }

        &:nth-child(4) {
          top: calc(9.5% + 14.5% + 15% + 15.8%);
        }

        &:nth-child(5) {
          top: calc(9.5% + 14.5% + 15% + 15.8% + 15.7%);
        }
      }

      input[type="checkbox"] {
        position: absolute;
        top: 87.8%;
        left: 6%;
        width: 5.5%;
        height: 0;
        padding-top: 5.5%;
        appearance: none;
        background-color: transparent;
        background-image: url("../assets/stew/section10/chk_off.png");
        background-size: contain;

        &:checked {
          background-image: url("../assets/stew/section10/chk_on.png");
        }
      }

      .agree {
        position: absolute;
        top: 87.8%;
        left: 14%;
        width: 70%;
        height: 0;
        padding-top: 5%;

        .text {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          text-align: left;
        }
      }
    }

    .btn {
      position: absolute;
      top: 92%;
      left: 50%;
      transform: translateX(-50%);
      width: 35%;
      height: 6.1%;
      border: 0;
      box-sizing: border-box;
      background-image: url("../assets/noodle/section9/button.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
</style>
