import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import StewView from "../views/StewView.vue";
import NoodleView from "../views/NoodleView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/stew",
    name: "stew",
    component: StewView,
  },
  {
    path: "/noodle",
    name: "noodle",
    component: NoodleView,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
